<template>
  <div class="popup" v-show="show">
    <transition name="el-zoom-in-center">
        <div class="center-nav">

        </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Offall",
  data(){
    return{
      show:true,

    }
  }
}
</script>

<style scoped lang="scss">
  .popup{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.6);
    z-index: 9999;
    //display: flex;
    //justify-content: center;
    //align-content: center;

    .center-nav{
      width: 500px;
      height: 500px;
      background-color: #ffffff;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -250px;
      margin-top: -250px;
    }


  }
</style>
