import axios from 'axios';

axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.post['Content-Type'] = 'text/plain;charset=UTF-8'

let service = axios.create({
    //baseURL: 'https://sandie.xinghan.vip' + '/api',
	baseURL: 'https://heping.xinghan.vip' + '/api',
    timeout: 10000
})

// 请求拦截 可在请求头中加入token等
service.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if ( token ) {
        config.headers['Authori-zation'] = 'Bearer ' + token;
    }
    return config
}, error => {
    return Promise.reject(error)
})

// 响应拦截 对响应消息作初步的处理
service.interceptors.response.use(response => {
        let status = response.data ? response.data.status : 0
        const code = status
        switch (code) {
            case 200:
                return response.data
            case 400:
            case 400011:
            case 400012:
                return Promise.reject(response.data || {msg: '未知错误'})
            case 410000:
            case 410001:
            case 410002:
                localStorage.clear()
                break
            case 410003:

                break
            default:
                break
        }
    },
    error => {
        Notification.error(error.msg)
        return Promise.reject(error)
    }

)

export default service
