<template>
  <div class="onebg acea-row row-middle row-center">
    <div class="nav acea-row row-middle row-around">
      <div class="" @click="click(index+1)" v-for="(item,index) in list" :key="index">
        <img class="hover" :src="item.hover" alt="" v-if="tabindex == index+1">
        <img class="active" :src="item.active" alt="" v-else>
      </div>

    </div>
  </div>
</template>
<script>
import { getIndex } from '@/api/api'
export default {
  name:"newIndex",
  data(){
    return{
      tabindex: 0,
      list:[
        {
          hover:'https://nanrongcun.oss-cn-hangzhou.aliyuncs.com/pic/lianluozhan01.png',
          active:'https://nanrongcun.oss-cn-hangzhou.aliyuncs.com/pic/lianluozhan002.png'
        },
        {
          hover:'https://nanrongcun.oss-cn-hangzhou.aliyuncs.com/pic/jicenglifa01.png',
          active:'https://nanrongcun.oss-cn-hangzhou.aliyuncs.com/pic/jicenglifa002.png'
        },
        {
          hover:'https://nanrongcun.oss-cn-hangzhou.aliyuncs.com/pic/sanguanyiyuan01.png',
          active:'https://nanrongcun.oss-cn-hangzhou.aliyuncs.com/pic/sanguanyiyuan02.png'
        },
        {
          hover:'https://nanrongcun.oss-cn-hangzhou.aliyuncs.com/pic/zhitongche01.png',
          active:'https://nanrongcun.oss-cn-hangzhou.aliyuncs.com/pic/zhitongche002.png'
        },
      ],
      listid:[],


    }
  },
  mounted() {
    this.getindex();
  },
  methods:{
    getindex(){
      let that = this;
      getIndex({pid:''}).then(res=>{
        that.listid = res.data.data;
      })
    },
    click(val){
      this.tabindex = val;
      setTimeout(()=>{
        switch (val) {
          case 1:
            this.$router.push(`/ContactPoints?id=${this.listid[0].id}&type=bins`)
            break;
          case 2:
            this.$router.push(`/ContactPoints?id=${this.listid[1].id}&type=jiceng`)
            break;
          case 3:
            this.$router.push(`/ContactPoints?id=${this.listid[2].id}&type=san`)
            break;
          case 4:
            this.$router.push(`/ThroughTrain?category_id=3&router=3`)
            break;
        }
      },300)

    },
  }
}
</script>
<style lang="scss" scoped>
.nav{
  width: 90%;
  margin-top: 100px;

  .index_icon{
    width: 500px;
    height: 500px;
  }

  .hover{
    width: 570px;
    height: 510px;
  }

  .active{
    width: 460px;
    height: 430px;
  }


}


</style>
