import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import '../src/assets/css/font.css'
import  'amfe-flexible';

import offall from "@/components/Offall.vue";

import axios from 'axios';


Vue.prototype.$axios = axios;

Vue.use(ElementUI)

Vue.component('offall',offall)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

